import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import BackToTop from 'vue-backtotop'
import 'swiper/swiper-bundle.css'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import '../src/assets/css/icofont.min.css'
import '../src/assets/css/gordita-font.css'
import '../src/assets/scss/style.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App)

app.use(router)

app.use(VueAxios, axios)

app.use(BackToTop)

app.mount('#app')

/*createApp(App).use(router,axios,VueAxios).use(BackToTop).mount('#app')*/

/*createApp(App).use([router,BackToTop,axios,VueAxios]).mount('#app')*/
