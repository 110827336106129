import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  /*{
    path: '/home-2',
    name: 'home-2',
    component: () => import('../pages/HomeTwo.vue')
  },
  {
    path: '/home-3',
    name: 'home-3',
    component: () => import('../pages/HomeThree.vue')
  },
  {
    path: '/home-4',
    name: 'home-4',
    component: () => import('../pages/HomeFour.vue')
  },
  {
    path: '/home-5',
    name: 'home-5',
    component: () => import('../pages/HomeFive.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../pages/BlogGrid.vue')
  },
  {
    path: '/blog-list',
    name: 'blog-list',
    component: () => import('../pages/BlogList.vue')
  },
  {
    path: '/blog-detail/:id',
    name: 'blog-detail',
    component: () => import('../pages/BlogDetail.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../pages/Faq.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../pages/Register.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Login.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../pages/contact.vue')
  },*/
  {
    path: '/salentoTruck',
    name: 'salento-truck',
    component: () => import('../pages/SalentoTruck.vue')
  },
  {
    path: '/competitions-manager',
    name: 'competitions-manager',
    component: () => import('../pages/CompetitionsManager.vue')
  },
  {
    path: '/competitions-manager/competitors',
    name: 'competitors-manager',
    component: () => import('../pages/CMPMCompetitors.vue')
  },
  {
    path: '/competitions-manager/trucks',
    name: 'truks-list',
    component: () => import('../pages/CMPMTruck.vue')
  },
  {
    path: '/competitions-manager/granPrix',
    name: 'gran-prix',
    component: () => import('../pages/CMPMGPrix.vue')
  },
  {
    path: '/competitions-manager/truckPulling',
    name: 'truck-pulling',
    component: () => import('../pages/CMPMTPulling.vue')
  },
  {
    path: '/competitions-manager/bestTruck',
    name: 'best-truck',
    component: () => import('../pages/CMPMBTruck.vue')
  },
  {
    path: '/gameSubscribe/',
    name: 'game-subscribe',
    component: () => import('../pages/GameSubscribe.vue')
  },
  {
    path: '/iscrizioni/',
    name: 'competition-subscribe',
    component: () => import('../pages/CompetitionSubscribe.vue')
  },
  {
    
    path: '/404',
    name: '404',
    component: () => import('../pages/404.vue')
  },

  

]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(function (to, from, next) {
  console.log(to)
  if (!!to.hash === false) {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
  // window.location.reload()
  next()
})
export default router
