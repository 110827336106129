<template>
    <!-- Begin Hero Area -->
    <div class="hero-area hero-bg hero-style-1" data-bg-image="" >
        <div class="hero-item">
            <div class="hero-img-wrap hero-pad" style="margin-left: 0;">
                <div class="hero-img">
                    <div class="single-img text-center mx-auto">
                        <img src="images/logo/st.png "  style="width: 70%;" alt="Hero Image">
                    </div>
                </div>
                <!-- <div class="hero-img with-group">
                    <div class="single-img">
                        <img src="images/hero/inner-img/img1.1.png" style="max-height:348px; border-radius:25px;" alt="Hero Image">
                    </div>
                    <div class=" mt-20">
                        
                        <div class="single-img">
                            <img src="images/hero/inner-img/1-3-398x350.png" alt="Hero Image">
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="hero-content">
                <span class="category">I-Future</span>
                <h1 class="title playfair-font">Scopri il nostro prossimo evento</h1>
                <p class="desc">Entra nel rombante mondo del Salento Truck</p>
                <div class="btn-wrap">
                    <router-link class="btn btn-custom-size btn-lg sapphire-hover roboto-font" to="/salentoTruck">Salento Truck</router-link>
                </div>
            </div>
        </div>
    </div>
    <!-- Hero Area End Here -->
</template>

<style >

    @media (min-width: 576px) and (max-width: 992px) {
        .hero-pad {
        margin-top:50px
    }

    }

    @media (min-width: 991px) {
        .hero-pad {
        margin-top:250px
    }
    }

</style>

<script>
export default {
  data () {
    return {
      heroBg: 'images/hero/bg/6-1.jpg'
    }
  }
}
</script>
