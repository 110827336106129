<template>
    <!-- Begin Footer Area -->
    <footer>
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-lg-4">
                        <div class="footer-item">
                            <router-link to="/" class="footer-logo mb-0"><img src="images/logo/logoIFTLite.png" alt="Logo"></router-link>
                            <p class="footer-desc">Associazione Culturale iFuture </p>
                            <div class="footer-social-link">
                                <ul>
                                    <li v-for="(social, index) in socials" :key="index">
                                        <a :href="social.link"><i v-bind:class="social.iconName"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-8">
                        <div class="footer-item-wrap row">
                            <div class="col-lg-4 col-md-4 col-sm-6 pt-5 pt-lg-0">
                                <div class="footer-item">
                                    <div class="footer-list">
                                        <h2 class="title playfair-font">Contact Us</h2>
                                        <ul>
                                            <li>
                                                <a href="mailto://info@i-future.com"><i class="icofont-envelope-open"></i> info@i-future.it</a>
                                            </li>
                                            <li>
                                                
                                                <a><i class="icofont-location-pin"></i> Via Provinciale per Supersano km3, Casarano (LE)</a>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-4 col-sm-6 pt-5 pt-lg-0">
                                <div class="footer-item footer-ps-xl-30">
                                    <div class="footer-list">
                                        <h2 class="title playfair-font">Eventi</h2>
                                        <ul>
                                            
                                            <li><router-link to="/salentoTruck"> <i class="icofont-bullhorn"></i> Salento truck</router-link></li>
                                            <li>
                                                <a href="mailto://partner@i-future.com"> <i class="icofont-envelope-open"></i> partner@i-future.it</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-6 pt-5 pt-lg-0">
                                <div class="footer-item footer-ps-xl-20">
                                    <div class="footer-list">
                                        <h2 class="title playfair-font">Social</h2>
                                        <ul>
                                            <li>                                           
                                                <a  href="https://www.facebook.com/iFuture.casarano"><i class="icofont-facebook"></i> Facebook</a>
                                            </li>
                                            <li> 
                                                <a  href="https://instagram.com/ifuture_casarano?igshid=YmMyMTA2M2Y="><i class="icofont-instagram"></i> Instagram</a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/ifuture_14?s=11&t=7ZFv-_vKZhXZr0zeUD_2sA"><i class="icofont-twitter"></i> Twitter</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer-bottom-item with-border" >
                            <div class="footer-copyright">
                                <span >Powered <i class="icofont-code-alt" style="color:#ee7214;" ></i> by
                                <a href="https://www.theginlabs.it"  rel="noopener" target="_blank"><img src="/images/footer/inner-bg/TGL.png"></a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Area End Here -->

    <!-- Begin Scroll To Top -->
    <back-to-top bottom="50px" right="50px">
        <button type="button" class="footer-scroll-top scroll-to-top style-1 show border-0">
            <i class="icofont-arrow-up"></i>
        </button>
    </back-to-top>
    <!-- Scroll To Top End Here -->
</template>

<script>

export default {
  data () {
    return {
      socials: [
        {
          link: 'https://www.facebook.com/iFuture.casarano',
          iconName: 'icofont-facebook'
        },
        {
          link: 'https://instagram.com/ifuture_casarano?igshid=YmMyMTA2M2Y=',
          iconName: 'icofont-instagram'
        },
        {
          link: 'https://twitter.com/ifuture_14?s=11&t=7ZFv-_vKZhXZr0zeUD_2sA',
          iconName: 'icofont-twitter'
        }
      ]
    }
  }
}
</script>
