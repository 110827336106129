<template>
    <!-- Begin Partner Area -->
    <div id="sponsor" class="partner-area ">
        <div class="container">
            <div class="row mt-5">
                <div class="col-12">
                    <div class="section-area section-style-2">
                      <h2 class="section-title">I nostri Partners</h2>
                        <!-- Partner Arrows -->
                        <div class="partner-arrow-wrap">
                            <!-- Swiper Navigation Start -->
                            <div class="partner-button-prev swipper-arrow">
                                <i class="icofont-double-left"></i>
                            </div>
                            <div class="partner-button-next swipper-arrow">
                                <i class="icofont-double-right"></i>
                            </div>
                            <!-- Swiper Navigation End -->
                        </div>
                    </div>
                    <div class="partner-slider">

                        <swiper class="partner-slider"
                            :breakpoints="swiperOptions.breakpoints"
                            :slides-per-view="3"
                            :spaceBetween="60"
                            :loop="true"
                            :navigation="swiperOptions.navigation"
                            @swiper="onSwiper"
                        >
                            <swiper-slide class="partner-item" v-for="(partner, index) in partners" :key="index">
                                <span class="partner-img">
                                  <a :href=partner.url target="_blank"><img :src="`${partner.partnerImage}`" :alt="partner.alt"></a>
                                </span>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Partner Area End Here -->
</template>

<script>
import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/vue'
SwiperCore.use([Navigation])

export default {
  components: {
    Swiper,
    SwiperSlide
  },

  data () {
    return {
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          1100: {
            slidesPerView: 4,
            spaceBetween: 60
          }
        },
        navigation: {
          nextEl: '.partner-area .partner-button-next',
          prevEl: '.partner-area .partner-button-prev'
        }
      },

      partners: [
      {
          partnerImage: 'images/partner/UTA.png',
          alt: 'UTA',
          url:'https://web.uta.com/it/uta-card'
        },
        {
          partnerImage: 'images/partner/primiceri.png',
          alt: 'Trasporti F.lli Primiceri',
          url:'https://www.trasportiprimiceri.com/'
        },
        {
          partnerImage: 'images/partner/lotras.png',
          alt: 'Lotras logistics & Eco Transport',
          url:'https://www.lotras.it/it/'
        },
        {
          partnerImage: 'images/partner/gpl.png',
          alt: 'Green Planet Logistics',
          url:'https://www.greenplanetlogistics.net/'
        },
        {
          partnerImage: 'images/partner/serra.png',
          alt: 'Officine Serravezza',
        },
        {
          partnerImage: 'images/partner/itallog.png',
          alt: 'ItsLogistica',
          url:'https://itslogistica.it/'
        },
        {
          partnerImage: 'images/partner/tm.png',
          alt: 'Meccaniche Anastasia s.r.l.',
          url:'https://www.t-m-srl.it/'
        },
        {
          partnerImage: 'images/partner/ascla.png',
          alt: 'Ascla ',
          url:'https://www.ascla.net/'
        },
        {
          partnerImage: 'images/partner/euroverde.png',
          alt: 'Euroverde Garden Center',
          url:'https://euroverdegarden.it/'
        },
        {
          partnerImage: 'images/partner/ACI.png',
          alt: 'ACI',
        },
        {
          partnerImage: 'images/partner/SARA.png',
          alt: 'Sara Assicurazioni',
        },
        {
          partnerImage: 'images/partner/osm.png',
          alt: 'OSM Partner',
          url:'https://www.osmpartner.com/'
        },
        {
          partnerImage: 'images/partner/tgl.png',
          alt: 'TheGINLABs',
          url:'https://www.theginlabs.it'
        },
        {
          partnerImage: 'images/partner/persone.png',
          alt: 'Autoscuola Personè',
          url:'https://www.autoscuolepersone.com/chi-siamo'
        },
        {
          partnerImage: 'images/partner/mollificio.png',
          alt: 'Mollificio Anastasia',
          url:'https://mollificioanastasia.com/'
        },
        {
          partnerImage: 'images/partner/pw.png',
          alt: 'Palletways',
          url:'https://it.palletways.com/it'
        },
        {
          partnerImage: 'images/partner/alpak.png',
          alt: 'Alpak',
          url:'https://alpak.it/'
        },
        {
          partnerImage: 'images/partner/marevivo.png',
          alt: 'Mare Vivo',
          url:'https://www.mondomarevivo.com/'
        },
        {
          partnerImage: 'images/partner/primicerilogistica.png',
          alt: 'Primiceri Logistica',
        },
        {
          partnerImage: 'images/partner/decorline.png',
          alt: 'Decorline',
        },
        {
          partnerImage: 'images/partner/ediltiler.png',
          alt: 'Ediltiler',
        },
        {
          partnerImage: 'images/partner/sace.png',
          alt: 'Sace Macchine',
          url:'http://www.sacemacchine.com/'

        },
        // {
        //   partnerImage: 'images/partner/fashionTruck.png',
        //   alt: 'Fashion Truck',
        //   url:''
        // },
      ],
    }
  },

  methods: {
    onSwiper (swiper) {
      this.swiper = swiper
    }
  }
}
</script>
